import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Latin - Χοροί του κόσμου";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Ο χορός LATIN έχει την καταγωγή του στη Λατινική Αμερική. Τυπικοί
          χοροί LATIN είναι οι Τσα τσα τσα, Ρούμπα, Σάμπα, Σάλσα, Μάμπο.
        </P>
        <P>
          Στη σχολή μας διδάσκονται τα βασικότερα είδη LATIN και άλλων γνωστών
          εκφραστικών Ethnic χορών.
        </P>
        <P>
          Γρήγοροι και αργοί ρυθμοί, βήματα συγκεκριμένα και ελεύθερες φιγούρες
          κέφι, ένταση και Ethnic μουσική, δημιουργούν ένα πολύχρωμο ρυθμικό
          σύνολο, που κάνει καλό στο σώμα και την ψυχή.
        </P>
        <P>
          Οι δάσκαλοι μας προετοιμάζουν τους μαθητές μας για διαγωνισμούς υψηλού
          κύρους οπου εχουμε καταφέρει να διακριθούμε σε πολύ καλές θέσεις.
        </P>
      </Section.Main>
    </Layout>
  );
}
